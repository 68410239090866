<template>
    <div class="content">
      <el-row class="bgW">
        <el-col :span="22">
          <!--检索条件-->
          <el-form class="search" :inline="true" label-suffix="：" size="mini" :model="search">
            <el-form-item label="出货日期">
              <el-date-picker
                v-model="value1"
                type="daterange"
                range-separator="至"
                class="w220"
                start-placeholder="开始日期"
                end-placeholder="结束日期"
                value-format="yyyy-MM-dd"
                @change="searchTime"
              ></el-date-picker>
            </el-form-item>
            <el-form-item label="客户名称" v-if="!clienId">
              <el-input
                v-model="search.consignee"
                maxlength="30"
                class="w120"
                placeholder="请输入"
                clearable
              />
            </el-form-item>
            <el-form-item label="门店名称" v-if="!clienId">
              <el-input
                v-model="search.shop_name"
                maxlength="30"
                class="w120"
                placeholder="请输入"
                clearable
              />
            </el-form-item>
            <el-form-item label="商品名称" v-if="!clienId">
              <el-input
                v-model="search.goods_name"
                maxlength="30"
                class="w150"
                placeholder="请输入"
                clearable
              />
            </el-form-item>
          </el-form>
        </el-col>
        <el-col :span="2" style="text-align: right">
          <el-button type="primary" icon="el-icon-search" size="mini" @click="getList('search')">搜索</el-button>
        </el-col>
      </el-row>
      <div class="accountCon">
        <div class="btnBox">
          <el-button
            type="primary"
            class="mb10"
            size="mini"
            @click="tapBtn('export')"
          >导出数据</el-button>
        </div>
        <!--列表-->
        <el-row>
          <el-col :span="24">
            <el-table
              :data="list"
              size="small"
              border
              :cell-style="$style.cellStyle"
              :header-cell-style="$style.rowClass"
              @selection-change="handleSelectionChange"
            >
              <ElTableColumn type="selection" width="50" />
              <!-- <ElTableColumn label="ID" prop="id" /> -->
              <ElTableColumn label="客户姓名1" prop="customer_name" />
              <ElTableColumn label="客户K3编码" prop="customer_code" />
              <ElTableColumn label="存货日期" prop="deal_time" />
              <ElTableColumn label="产品名称" prop="goods_name" />
              <ElTableColumn label="产品编码" prop="goods_code" />
              <ElTableColumn label="规格" prop="spec_name" />
              <!-- <ElTableColumn label="标准单价" prop="goods_price" /> -->
              <ElTableColumn label="销售单价" prop="pay_price" />
              <ElTableColumn label="存货数量" prop="takeout_stock_num" />
              <ElTableColumn label="销售金额" prop="sales_amount" />
              <ElTableColumn label="出货数量" prop="shipment_num" />
              <ElTableColumn label="出货金额" prop="shipment_amount" />
              <ElTableColumn label="剩余数量" prop="residual_shipment_num" />
              <ElTableColumn label="剩余销售金额" prop="residual_sales_amount" />
              <ElTableColumn label="门店"  >
              <template slot-scope="{ row }">
                    {{row.market_name}}-{{row.shop_name}}
                </template>
              </ElTableColumn>
              <!-- <ElTableColumn label="市场" prop="market_name" /> -->
              <ElTableColumn label="业务类型" prop="business_type" />
              <ElTableColumn label="操作" fixed="right">
                <template slot-scope="{ row }">
                    <el-button type="text" v-if="row.customer_name != '合计'"  @click="tapBtn('出货明细',row)" >出退货明细</el-button>
                </template>
              </ElTableColumn>
            </el-table>
            <!-- <div style="float:right;font-size: 18px;margin: 15px 0;">
              出库总额：<span style="color: #bd230f;">999999999995559元</span> 
            </div> -->
            <pagination
              class="page tc mt10"
              :total="page.total"
              :page.sync="page.page"
              :limit.sync="page.limit"
              @pagination="getList"
            />
          </el-col>
        </el-row>
      </div>
    </div>
  </template>
  
  <script>
  import { mapGetters } from "vuex";
  import pagination from "@/components/Pagination";
  import { prestockList,shipment,shopPrestockList,preListExit} from "@/api/prestock";
  class shipmentDetail {
    max_num = 0;
    num = 0;
    reason = "";
    id = 0
  }
  class Rules {
    num = [{ required: true, message: "请选", trigger: "blur" }];
    reason = [{ required: true, message: "请填写备注", trigger: "blur" }];
  }
  class Page {
    total = 0;
    page = 1;
    limit = 10;
  }
  class Search {
    start_time = "";
    end_time = "";
    consignee = "";
    shop_name = "";
    goods_name = "";
  }
  export default {
    name: "OrderList",
    components: {
      pagination
    },
    props: {
      clienId: {
        type: String,
        default: ""
      },
      detail: {
        type: Object,
        default: () => {
          return {};
        }
      }
    },
    data() {
      return {
        shipmentDetail: new shipmentDetail(), //出货表单
        search: new Search(), // 检索条件
        page: new Page(), // 分页
        list: [], // 数据展示
        idList: "",
        value1: [],
        sum_amount: "noShow",
        is_show_add:true,
      };
    },
    computed: {
      ...mapGetters(["userInfo"])
    },
    mounted() {
      this.getList();
    },
    methods: {
      // 获取列表
      getList(option) {
        if (option == "search") this.page = new Page();
        shopPrestockList({
          ...this.search,
          ...this.page,
          uid: this.clienId ? this.clienId : "",
        }).then(res => {
          this.list = res.data.list;
          this.page.total = res.data.dataCount;
          if (this.search.end_time) {
            this.sum_amount = res.sum_amount;
          } else {
            this.sum_amount = "noShow";
          }
        });
      },
      // 日期搜索
      searchTime(val) {
        if (val) {
          this.search.start_time = val[0];
          this.search.end_time = val[1];
        } else {
          this.search.start_time = "";
          this.search.end_time = "";
        }
      },
      // 
      tapBtn(type, row) {
        console.log(row,'row');
        this.shipmentDetail.num = 0;
        this.shipmentDetail.reason = "";
        switch (type) {
          case "出货":
            this.dialogVisible = true;
            this.shipmentDetail.max_num =parseFloat(row.num);
            this.shipmentDetail.id = row.id;
            break;
          case "退货":
            this.salesReturnShow = true;
            break;
          case "出货明细":
            let query = {
              id:row.id,
              start_time:this.search.start_time,
              end_time:this.search.end_time
            };
            this.$router.push(
              { name: "预存货列表明细", query: query }
            );
            break;   
          case "export":
          this.hint("导出预存货列表", "export");
          break;
        }
      },

      // 出货提交
      submitForm(formName) {
        switch(formName){
          case "shipmentDetail":
          this.hint("商品/服务出货", "shipment");
            break;
        }
      },

      // 提示
      hint(text, type,) {
        console.log(type,"type")
        this.$confirm(`${text},是否确定？`, "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning"
        })
          .then(() => {
            switch (type) {
            case "shipment":
              this.shipment();
            break;
            case "export":
              this.getExport();
            break;
          }
          })
          .catch(() => {
            this.$message({
              type: "info",
              message: "已取消"
            });
          });
      },
      // 预存货
      shipment() {
        shipment({ ...this.shipmentDetail,}).then(res => {
          this.$message({
            message: res.msg,
            type: "success"
          });
          this.dialogVisible = false;
          this.getList();
        });
      },
      // 导出
      getExport() {
        preListExit(this.search).then(res => {
          window.open(res.data.url);
        });
      },
  
      // 改变表格选中状态
      handleSelectionChange(val) {
        this.idList = val.map(i => {
          return i.id;
        });
      }
    }
  };
  </script>
  
  <style lang="scss" scoped>
  .accountCon {
    background: #fff;
    padding: 10px 0px 80px;
    .btnBox {
      display: flex;
      justify-content: space-between;
    }
  }
  .reveal {
    text-align: right;
    padding: 20px 0;
    .peril {
      font-weight: bold;
    }
  }
  .btnBox {
    width: 100%;
    text-align: center;
  }
  </style>
  